
import { Link } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react';

export function TooltipForms({ type, children }) {
    const refTooltip = useRef()
    const refTooltipIcon = useRef()

    const [top, setTop] = useState(22);
    const [left, setLeft] = useState(20);
    const [width, setWidth] = useState(550);

    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 500) {
            setWidth(window.innerWidth - 50)
        }
    }, [])

    const openUrl = (url, isInNewTab = true) => {
        isInNewTab ? window.open(url, '_blank') : window.open(url, '_self');
    }
    const updateTopLeft = (ev) => {
        if (isChanged) return
        const diffToSet = ev.clientY - refTooltip.current.clientHeight - 35
        const diff = refTooltipIcon.current.getBoundingClientRect().y - refTooltip.current.clientHeight - 35
        if (diff < 0) {
            setTop(-diffToSet)
            setLeft(20)
        }
        setIsChanged(true)
    }

    const getContentStyles = () => {
        return {
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`
        };
    };

    return (
        <div className="tooltip"
            onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); }} onMouseOver={(ev) => { updateTopLeft(ev); }}
            ref={refTooltipIcon}
        >
            {children}
            <p className="top"
                ref={refTooltip}
                style={getContentStyles()}
            >
                {type === 'personalDelivery' &&
                    <>
                        <p className='details'>
                            מסירה אישית לפי הוראות
                            &nbsp;
                            <span type="button" style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }} onClick={() => { openUrl('https://www.nevo.co.il/laws/#/5f3e8ec8d7503c36a39ec9f8'); }}>
                                פרק י״ט לתקנות סדר הדין האזרחי, תשע"ט-2018
                            </span>
                            &nbsp;
                            לרבות ביצוע עד 3 ביקורים בכתובת מאומתת כנכונה, החתמת טופס אישור מסירה שאנו מייצרים עבור כל הזמנה (וזמין באזור האישי ללקוחות רשומים) והחזרה עם תצהיר מוסר מאומת ע״י עו״ד לצורך הגשה לבית המשפט או הוצאה לפועל.
                            <br />
                            ניתן לבחור איסוף המסמכים עם שליח או העלאת קובץ המסמכים הנמסרים, אותם אנו נדפיס ונכרוך בעותקים לפי פרטי ההזמנה.
                            <br />
                            ניתן להוסיף מספר נמענים בתהליך ההזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביקור ראשון תוך 5 ימי עסקים מיום קבלת המסמכים, וכל ביקור נוסף תוך 5 ימי עסקים מהביקור הקודם
                            <br />
                            דחוף - ביקור ראשון תוך 1 ימי עסקים, וכל ביקור נוסף תוך 2 ימי עסקים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביקור ראשון ביום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/service-of-process/') }}>פרטים נוספים</span>
                        <br />
                        <p className='details'>
                            *אם אתם צריכים שליחות למשרדי עורכי דין או משרדים ממשלתיים להחתמת ״נתקבל״ בלבד, בחרו בשירות של
                            &nbsp;
                            <Link to="/forms/office-delivery" className='link-to'>המצאות למשרדים</Link>
                        </p>
                    </>}
                {type === 'apostilSubmission' &&
                    <>
                        <p className='details'>
                            שליחות למשרד החוץ בירושלים להחתמת כל סוגי התעודות הציבוריות
                            <br />
                            <span style={{ fontStyle: 'italic' }}>
                                [תעודת לידה, תמצית רישום, תעודת נישואין, תעודת גירושין, תעודת יושר (מידע פלילי), תעודת בגרות, תעודת הסמכה ועוד]
                            </span>
                            &nbsp;
                            בחותמת אפוסטיל בכל רמת דחיפות נדרשת.
                            <br />
                        </p>
                        <p className='details'>
                            לצורך החתמת אפוסטיל לתעודת בית ספר / תעודה אקדמאית, נדרש למסור שני עותקים- תעודה מקורית שעליה תהיה חתימת פקיד מורשה מטעם משרד החינוך וסריקה של התעודה עם חותמת נאמן למקור עם חתימה של פקיד מורשה מטעם משרד החינוך.
                        </p>
                        <p className='details'>
                            ניתן להוסיף בתהליך ההזמנה ביצוע אימות מקדים עבור התעודות שמחייבות זאת (ראו לאחר בחירת התעודה מידע נוסף) וניתן להוסיף לתשלום את סכום האגרה שמחייב משרד החוץ עבור אימות של כל תעודה (38₪ לתעודה), כשהתעודה נשלחת למדינה החברה באמנת האג.
                        </p>
                        <p className='details'>
                            *אם אתם צריכים חותמת אפוסטיל על אישור נוטריוני / תרגום נוטריוני - בחרו בשירות של אימות
                            &nbsp;
                            <Link className='link-to' to="/forms/apostille-in-court">
                                אפוסטיל בית משפט
                            </Link>
                            .
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 30 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)
                            <br />
                            דחוף - ביצוע תוך 14 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)
                            <br />
                            בהול - ביצוע תוך 8 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/apostille/') }}>פרטים נוספים</span>
                    </>}
                {type === 'apostilInCourt' &&
                    <>
                        <p className='details'>
                            שליחות לבית המשפט לצורך החתמת מסמכים שאינם ציבוריים בחותמת אפוסטיל (המודבקת על גבי האישור הנוטריוני).
                            <br />
                            מסמכים עם אישור נוטריוני יכולים להיות למשל: תרגום נוטריוני, צוואה, חוזה, ייפוי כח, צילום דרכון, ובתנאי שאושרו על ידי נוטריון מוסמך במדינת ישראל.
                        </p>
                        <p className='details'>
                            בשקלול המחיר הסופי יתווסף תשלום אגרה בסך 38₪ עבור כל מסמך לאימות, ובתנאי שהמסמכים מיועדים למדינה שחברה בהאמנת האג.
                        </p>
                        <p className='details'>
                            *אם אתם צריכים חותמת אפוסטיל למסמך ציבורי - בחרו בשירות של אימות
                            &nbsp;
                            <Link className='link-to' to="/forms/apostille">
                                אפוסטיל משרד החוץ
                            </Link>
                            .
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 7 ימי עסקים מיום קבלת המסמכים
                            <br />
                            דחוף - ביצוע תוך 3 ימי עסקים מיום קבלת המסמכים
                            <br />
                            *במקרה של מעל 20 מסמכים, יום עסקים נוסף עבור כל 10 מסמכים
                            <br />
                            בהול - ביצוע תוך 1 ימי עסקים מיום קבלת המסמכים
                            <br />
                            *במקרה של מעל 10 מסמכים, יום עסקים נוסף עבור כל 10 מסמכים
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/apostille_in_court/') }}>פרטים נוספים</span>
                    </>}
                {type === 'phoneLocate' &&
                    <>
                        <p className='details'>
                            איתור מספר פלאפון של אדם באמצעות חוקר פרטי בעל רישיון משרד המשפטים.
                            (לרבות איתור פלאפון בבעלות חברה)
                            <br />
                            האיתור לוקח כמה ימים בודדים ובסיומו אתם תקבלו דוח איתור מפורט.
                            <br />
                            ניתן להוסיף מספר מאותרים בתהליך ההזמנה
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/phone-locate/') }}>פרטים נוספים</span>
                    </>}
                {type === 'addressLocate' &&
                    <>
                        <p className='details'>
                            איתור כתובת מגורים מדויקת לחייב מתחמק או אדם שכתובתו לא מעודכנת במשרד הפנים, באמצעות חוקר פרטי בעל רישיון משרד המשפטים.
                            <br />
                            אנו מתחייבים לאיתור מוצלח. כלומר אם לא איתרנו -
                            &nbsp;
                            <span style={{ fontFamily: 'AlmoniDemiBold' }} >
                                כספכם יוחזר במלואו!
                            </span>
                            <br />
                            האיתור לוקח כמה ימים בודדים ובסיומו אתם תקבלו דוח איתור מפורט אותו ניתן להגיש לבתי משפט / הוצל״פ כהוכחה לאימות כתובתו של אדם.
                            <br />
                            ניתן להוסיף מספר מאותרים בתהליך ההזמנה.
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/person-locate/') }}>פרטים נוספים</span>
                        <p className='details'>
                            *אם אתם רוצים לדעת את הכתובת המעודכן במשרד הפנים, בחרו בשירות של
                            &nbsp;
                            <Link to="/forms/person-locate">
                                <span className='link-to' >
                                    איתור מען (שאילתא)
                                </span>
                            </Link>
                            .
                        </p>
                    </>}
                {type === 'idLocate' &&
                    <>
                        <p className='details'>
                            איתור מספר זהות של אדם באמצעות חוקר פרטי בעל רישיון משרד המשפטים.
                            <br />
                            אנו מתחייבים לאיתור מוצלח!
                            לא איתרנו -
                            &nbsp;
                            <span style={{ fontFamily: 'AlmoniDemiBold' }} >
                                כספכם יוחזר במלואו!
                            </span>
                            <br />
                            לאחר השלמת ההזמנה תקבלו הצעת מחיר לביצוע האיתור, אשר לוקח בין 2-5 ימי עסקים.
                            <br />
                            ניתן להוסיף מספר מאותרים בתהליך ההזמנה.
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/ID-locate/') }}>פרטים נוספים</span>
                        <p className='details'>
                            *הזינו כמה שיותר פרטים אודות המאותר כדי להוזיל את עלויות האיתור ולשפר את סיכויי הצלחתו
                        </p>
                    </>}
                {type === 'personLocate' &&
                    <>
                        <p className='details'>
                            איתור מען רשמי בהנפקת שאילתא ממשרד הפנים אודות כל אדם ושליחה בדוא״ל או בשליחות מהירה לפי בחירתכם.
                            <br />
                            ניתן להוסיף מספר מאותרים בתהליך ההזמנה
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 5 ימי עסקים
                            <br />
                            דחוף - ביצוע תוך 3 ימי עסקים
                            <br />
                            בהול - ביצוע תוך 1 ימי עסקים
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/person-locate/') }}>פרטים נוספים</span>
                        <p className='details'>
                            *אם אתם צריכים לאתר כתובת מגורים מדויקת לחייב מתחמק או אדם שכתובתו לא מעודכנת במשרד הפנים, ניתן לאתר את כתובת מגוריו של האדם באמצעות חוקר פרטי עם התחייבות לאיתור מוצלח בהזמנה של
                            &nbsp;
                            <Link to="/forms/address-locate">
                                <span className='link-to' >
                                    איתור כתובת מגורים
                                </span>
                            </Link>
                            .
                        </p>
                    </>}
                {type === 'officeDelivery' &&
                    <>
                        <p className='details'>
                            המצאות למשרדי עורכי דין או משרדים ממשלתיים והחתמת חותמת ״נתקבל״ עם תאריך על גבי עותק העמוד הראשון של המסמך המוגש.
                            <br />
                            ניתן לבחור  איסוף המסמכים עם שליח או העלאת קובץ המסמכים הנמסרים, אותם אנו נדפיס ונכרוך בעותקים לפי פרטי ההזמנה.
                            <br />
                            ניתן להוסיף מספר נמענים בתהליך ההזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ניסיון המצאה תוך 3 ימי עסקים מיום קבלת המסמכים
                            <br />
                            דחוף - ניסיון המצאה תוך 1 ימי עסקים מיום קבלת המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ניסיון המצאה ביום קבלת המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/office-delivery/') }}>פרטים נוספים</span>
                        <br />
                        <p className='details'>
                            *אם אתם צריכים למסור מסמכים במסירה אישית לפי הוראות
                            &nbsp;
                            <span className='link-to' onClick={() => { openUrl('https://www.nevo.co.il/laws/#/5f3e8ec8d7503c36a39ec9f8') }}>
                                פרק י״ט לתקנות סדר הדין האזרחי, תשע"ט-2018
                            </span>
                            &nbsp;
                            לרבות ביצוע עד 3 ביקורים בכתובת מאומתת כנכונה, החתמת טופס אישור מסירה והחזרה עם תצהיר מוסר מאומת ע״י עו״ד לצורך הגשה לבית המשפט או הוצאה לפועל, עברו והזמינו
                            &nbsp;
                            <Link className='link-to' to="/forms/service-of-process">
                                מסירה אישית
                            </Link>
                            .
                        </p>
                    </>}
                {type === 'documentTransfer' &&
                    <>
                        <p className='details'>
                            הזמנת משלוח מהיום להיום באזורים נתמכים, או מהיום למחר.
                        </p>
                        <p className='details'>
                            השירות מתאים למשלוח רגיל .
                        </p>
                        <p className='details'>
                            מהירות ביצוע
                        </p>
                        <p className='details'>
                            אפשרויות השילוח יוצגו אוטומטית במעמד ההזמנה בהתאם לכתובות האיסוף והמסירה, המרחק וזמינות השליחים.

                        </p>
                        <b className='details bold'>
                            רגיל:
                        </b>
                        <p className='details'>
                            מהיום להיום: מסירה עד סוף יום העסקים (18:00)
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע עד סוף היום (18:00), ביום העסקים שלמחרת.
                        </p>
                        <p className='details'>
                            מהיום למחר או יותר: מסירה תוך 3 ימי עסקים מהאיסוף
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע תוך 3 ימי עסקים נוספים.
                        </p>
                        <b className='details bold'>
                            דחוף:
                        </b>
                        <p className='details'>
                            מהיום להיום: מסירה תוך 4 שעות מהאיסוף
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע עד 13:00 ביום העסקים שלמחרת
                        </p>
                        <p className='details'>
                            מהיום למחר או יותר: מסירה תוך 2 ימי עסקים מהאיסוף
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע תוך 2 ימי עסקים נוספים.
                        </p>
                        <b className='details bold'>
                            בהול:
                        </b>
                        <p className='details'>
                            מהיום להיום: מסירה תוך 2 שעות מהאיסוף
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע סוף יום העסקים (18:00) ובמקרה שלא ניתן להחזיר בשעה זו, יוחזר עד שעה 13:00 ביום העסקים שלמחרת.
                        </p>
                        <p className='details'>
                            מהיום למחר או יותר: מסירה תוך 1 ימי עסקים מהאיסוף
                            במשלוח כפול (הלוך וחזור), ההחזרה תבוצע תוך 1 ימי עסקים נוספים.
                            שליחות מהיום-להיום תהיה זמינה להזמנות עד השעה 14:00, כשהמוצא הוא מהיישובים הבאים בלבד: תל אביב, רמת גן, בני ברק, חולון, ראשון לציון ולמרחק של עד 25 קילומטר.
                        </p>
                        {/*<p className='details'>*/}

                        {/*</p>*/}
                        {/*<p className='details'>*/}

                        {/*</p>*/}
                        {/*<p className='details'>*/}

                        {/*</p>*/}
                        {/*<p className='details'>*/}
                        {/*    אם מזמינים אחרי השעה 14:00, ניתן לבחור באיסוף עתידי וכך תוצג האפשרות לשליחות מהיום להיום:*/}
                        {/*</p>*/}

                        {/* <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 5 ימי עסקים מיום איסוף המסמכים, בשליחות כפולה עד 10 ימי עסקים.
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים, בשליחות כפולה עד 2 ימי עסקים.
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים, בשליחות כפולה עד 1 ימי עסקים
                            <br />
                            ביקור ראשון ביום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p> */}
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/document-transfer/') }}>פרטים נוספים</span>
                        <br />
                        <p className='details'>
                            *אם אתם צריכים למסור מסמכים במסירה אישית לפי הוראות
                            &nbsp;
                            <span className='link-to' onClick={() => { openUrl('https://www.nevo.co.il/laws/#/5f3e8ec8d7503c36a39ec9f8') }}>
                                פרק י״ט לתקנות סדר הדין האזרחי, תשע"ט-2018
                            </span>
                            &nbsp;
                            לרבות ביצוע עד 3 ביקורים בכתובת מאומתת כנכונה, החתמת טופס אישור מסירה והחזרה עם תצהיר מוסר מאומת ע״י עו״ד לצורך הגשה לבית המשפט או הוצאה לפועל, עברו והזמינו
                            &nbsp;
                            <Link className='link-to' to="/forms/service-of-process">
                                מסירה אישית
                            </Link>
                            .
                        </p>
                        <p className='details'>
                            *אם אתם צריכים שליחות למשרדי עורכי דין או משרדים ממשלתיים להחתמת ״נתקבל״ בלבד, בחרו בשירות של
                            &nbsp;
                            <Link className='link-to' to="/forms/office-delivery">
                                המצאות למשרדים
                            </Link>
                        </p>
                    </>}

                {type === 'inheritanceRegistration' &&
                    <>
                        <p className='details'>
                            הגשה של מסמכים לרשם לענייני ירושה בכל הלשכות בארץ, בהפקדה מצולמת או בזימון תור עם חותמת ״נתקבל״, לפי בחירתכם בהזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>הגשת כל סוגי המסמכים:</span>
                            <br />
                            בקשה למתן צו ירושה
                            <br />
                            בקשה לצו קיום צוואה
                            <br />
                            בקשה למינוי מנהל עיזבון
                            <br />
                            התנגדות למתן צו קיום צוואה
                            <br />
                            התנגדות למתן צו ירושה
                            <br />
                            בקשה לביטוח צו קיום צוואה
                            <br />
                            בקשה לביטול צו ירושה
                            <br />
                            בקשה לתיקון צו קיום צוואה
                            <br />
                            הגשת בקשה מתוקנת
                            <br />
                            כל מסמך אחר
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/inheritance-registration/') }}>פרטים נוספים</span>
                    </>}

                {type === 'partnershipsRegistration' &&
                    <>
                        <p className='details'>
                            הגשה של מסמכים לרשם השותפויות בתל אביב / ירושלים בהפקדה מצולמת או בזימון תור עם חותמת נתקבל לפי בחירתכם בהזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>הגשת כל סוגי המסמכים:</span>
                            <br />
                            רישום שותפות כללית / זרה
                            <br />
                            רישום שותפות מוגבלת
                            <br />
                            פירוק שותפות
                            <br />
                            שינוי פרטי שותפות כללית או מוגבלת
                            <br />
                            רישום שם עסק
                            <br />
                            שינוי פרטי שותפות
                            <br />
                            כל מסמך אחר
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/partnerships-registration/') }}>פרטים נוספים</span>
                    </>}

                {type === 'associationsAndCompanies' &&
                    <>
                        <p className='details'>
                            הגשה של מסמכים לרשם העמותות וחל״צ בכל הלשכות בארץ, בהפקדה מצולמת או בזימון תור עם חותמת ״נתקבל״, לפי בחירתכם בהזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>הגשת כל סוגי המסמכים:</span>
                            <br />
                            בקשה לרישום עמותה
                            <br />
                            בקשה לרישום חברה לתועלת הציבור
                            <br />
                            בקשה לשינוי תקנון עמותה
                            <br />
                            שינוי כתובת עמותה
                            <br />
                            כל מסמך אחר
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/associations-and-companies/') }}>פרטים נוספים</span>
                    </>}

                {type === 'companiesRegistration' &&
                    <>
                        <p className='details'>
                            הגשה של מסמכים לרשם החברות בכל הלשכות בארץ, בהפקדה מצולמת או בזימון תור עם חותמת ״נתקבל״, לפי בחירתכם בהזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>הגשת כל סוגי המסמכים:</span>
                            <br />
                            בקשה לרישום חברה
                            <br />
                            דייוח שנתי לחברה
                            <br />
                            דיווח על שינויים במניות החברה
                            <br />
                            פירוק חברה
                            <br />
                            כל מסמך אחר
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/companies-registration/') }}>פרטים נוספים</span>
                    </>}

                {type === 'landRegistration' &&
                    <>
                        <p className='details'>
                            הגשה של מסמכים ללשכת רישום מקרקעין בכל הארץ, בהפקדה מצולמת או באיסוף תיקים מהלשכה.
                            <br />
                            *ייצור ייפוי כח אוטומטי בתהליך ההזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 15:00 יחל ביום שלמחרת
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                            <br />
                            *חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/land-registration/') }}>פרטים נוספים</span>
                    </>}


                {type === 'courtSubmission' &&
                    <>
                        <p className='details'>
                            הגשת מסמכים / פתיחת תיק / איסוף מסמכים, מכל ערכאה שיפוטית בארץ בהזמנה מותאמת אישית כולל ניסוח ייפוי כח לאיסוף מסמכים ותשלום אגרה בבית המשפט.
                            <br />
                            ניתן לבחור  איסוף המסמכים עם שליח או העלאת קובץ המסמכים המוגשים, אותם אנו נדפיס ונכרוך בעותקים לפי פרטי ההזמנה.
                            <br />
                            אם אתם צריכים למסור את המסמכים לצדדים נוספים
                            &nbsp;
                            <Link className='link-to' to="/forms/service-of-process">
                                במסירה אישית
                            </Link>
                            &nbsp;
                            או ב
                            <Link className='link-to' to="/forms/office-delivery">
                                המצאות למשרדים
                            </Link>
                            , ניתן להוסיף זאת בתהליך ההזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 3 ימי עסקים מיום איסוף המסמכים
                            <br />
                            דחוף - ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים
                            <br />
                            בהול - ביצוע ביום איסוף המסמכים
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/file-to-court/') }}>פרטים נוספים</span>
                    </>}

                {type === 'smallClaim' &&
                    <>
                        <p className='details'>
                            בתי המשפט לתביעות קטנות דנים בתביעות כספיות שמגיש אדם פרטי נגד אדם אחר או נגד חברה, לתשלום שאינו עולה על סך של 34,600 ₪ (נכון לינואר 2022), או תביעה להחלפת מוצר, תיקונו או לביטול עסקה בשווי האמור.                            <br />
                            <br />
                            <span style={{ fontFamily: 'AlmoniDemiBold' }}>
                                לחצו על השירות למילוי והגשת תביעה קטנה לפי טופס 1 לתוספת לתקנות שיפוט בתביעות קטנות (סדרי דין), תשל״ז-1976.
                            </span>
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>תוספות בתהליך ההזמנה:</span>
                            <br />
                            - איתור מספר פלאפון של הנתבע/ים
                            <br />
                            - איתור כתובת מגורים של הנתבע/ים באמצעות חוקר פרטי
                            <br />
                            - צירוף כל סוגי הקבצים לתמיכה בתביעתכם, לרבות קבצי אודיו (קול) ווידאו - אותם אנו נעביר לדיסק און קי (Disk on key) ונגיש לבית המשפט יחד עם התביעה.
                        </p>
                        <p className='details'>
                            אם אתם רוצים למסור את כתב התביעה לנתבעים
                            &nbsp;
                            <span className='link-to' onClick={() => { openUrl('https://delawvery.co.il/service-of-process/') }}>במסירה אישית</span>
                            &nbsp;
                            ולקדם את התיק מהר יותר, ניתן להוסיף זאת בתהליך ההזמנה.
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - הגשה תוך 7 ימי עסקים מיום ביצוע ההזמנה
                            <br />
                            דחוף - הגשה תוך 4 ימי עסקים מיום ביצוע ההזמנה
                            <br />
                            בהול - הגשה תוך 2 ימי עסקים מיום ביצוע ההזמנה
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/filing-a-small-claim/') }}>פרטים נוספים</span>
                    </>}

                {type === 'printingBinding' &&
                    <>
                        <p className='details'>
                            הדפסה וכריכה של כל סוגי המסמכים כמו כתבי טענות, כתבי בי-דין, או כל מסמך אחר, ובחירה בין איסוף מנקודות השירות שלנו, או שליחות עד משרדכם.
                            <br />
                            השירות כולל העלאת קבצים, בחירת כמות העמודים וצבע ההדפסה ובחירת מאפייני הדפסה: כריכת ספירלה, דגלונים לנספחים, פס לתיוק הקלסר בתיק המשפטי.
                            <br />
                            במידה ואתם צריכים להגיש את ההדפסה לבית המשפט, או למסור אותו במסירה אישית לצד להליך, עברו להזמנת השירות המתאים:
                            <br />
                        </p>
                        <p className='details'>
                            - הזמנת שירות
                            &nbsp;
                            <Link to="/forms/file-to-court">
                                <span className='link-to' >
                                    הגשה לבית משפט / בית דין
                                </span>
                            </Link>
                            .
                        </p>
                        <p className='details'>
                            - הזמנת שירות
                            &nbsp;
                            <Link to="/forms/service-of-process">
                                <span className='link-to' >
                                    מסירה אישית
                                </span>
                            </Link>
                            .
                        </p>
                        <p className='details'>
                            <span style={{ textDecoration: 'underline', fontFamily: 'AlmoniDemiBold' }}>רמת דחיפות</span>
                            <br />
                            רגיל - ביצוע תוך 5 ימי עסקים (יום ההזמנה לא נספר במניין הימים)
                            <br />
                            דחוף - ביצוע תוך 3 ימי עסקים (יום ההזמנה לא נספר במניין הימים)
                            <br />
                            בהול - ביצוע תוך 1 ימי עסקים (יום ההזמנה לא נספר במניין הימים)
                        </p>
                        <span className='link-to bold' onClick={() => { openUrl('https://delawvery.co.il/printing_binding/') }}>פרטים נוספים</span>
                    </>}
            </p>
        </div>
    )
}